.carousel-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
    overflow: hidden;
}

.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Mantiene la relación de aspecto y ajusta la imagen dentro del contenedor */
    object-position: center;
}

/* Estilos específicos para la versión móvil */
@media (max-width: 1250px) {
    .carousel-container {
        position: relative;
        width: 100%;
        height: 250px;
        padding-bottom: 75%; /* Relación de aspecto 16:9 */
        overflow: hidden;
    }
}
